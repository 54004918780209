import { ICharacteristic, ILinkedError, IWorkOrder , IWorkOrderType} from "./resource";
import { IIncidentType } from "./incidentTypes";

interface IEventBase {
    resourceId: string
    title: string
    start: Date
    end: Date
    id: string;
    displayColor: string | undefined;
    shiftLength: number;
    isCancellationRequestPendingOriginal?: boolean;
}

interface IEvent extends IEventBase {
    exceededMaxWorkingHours: boolean;
    description: string
    status: ESystemStatus
    calendarStatus: ECalendarStatus
    BookingId: string
    bookableResourceStatus: EBookingStatus
    preferredResourceId: string
    preferredResourceName: string
    isJobBidding: boolean
    originalResourceID: string
    jobType: string
    serviceAccount: IServiceAccount
    incidentType: IIncidentType
    bidders: IBookableResourceBooking[];
    changeStatus: ChangeStatus;
    errors?: string[]
    displayCost: number | null; //if reassigned, this will be set to null. if assigned back to original, value will set from actualCost
    actualCost: number;
    isOverTime: boolean;
    missingSkills: boolean;
    unavailable: boolean;
    loadingLock?: boolean;
    loading: boolean;
    validating: boolean;
    resourcePublishedAs: string;
    detailsModified?: boolean;
    display: string | undefined;
    editable: boolean | undefined;
    resourceEditable: boolean | undefined;
    cancellationRequestComments?: string;    
    workOrderType: IWorkOrderType;    
}

interface IOtherSiteEvent extends IEventBase {
    editable: false;
    resourceEditable: false;
    startEditable: false;
    otherSite: true;
    jobType: string;
    customerFullName?: string;
    siteLocation: string;
    workOrderType: IWorkOrderType;   
}


interface IBookableResourceBooking {
    bookingStatus: string;
    id: string;
    bookableResourceId: string;
    bookableResource: any;
    errors: ILinkedError[];
    isOverTime: boolean;
    missingSkills: boolean;
    unavailable: boolean;
    exceededMaxWorkingHours: boolean;
}


enum ChangeStatus {
    UnChanged = 0,
    New = 1,
    Modified = 2,
    Deleted = 3
}

interface IServiceAccount {
    name: string
    accountId: string
    accountNumber: string
}

enum EBookingStatus {
    "Scheduled" = 'f16d80d1-fd07-4237-8b69-187a11eb75f9', //Green
    "ValidationError" = '432cdef3-2ace-ea11-a812-000d3ad0ea2d', //Yellow
    "Cancelled" = '0adbf4e6-86cc-4db0-9dbb-51b7d1ed4020' // Red
}


enum ECalendarStatus {
    scheduled,
    assigned,
    issue,
    error
}

enum EWorkWorderType {
    Active,
    OnCall,
    Sleepover,
    AvantCareServiceTypeDonotremove
}


enum ESystemStatus {
    'Unscheduled' = 690970000,
    'Scheduled' = 690970001,
    'In Progress' = 690970002,
    'Completed' = 690970003,
    'Posted' = 690970004,
    'Canceled' = 690970005,
}

export { EBookingStatus, ESystemStatus, ChangeStatus, ECalendarStatus,EWorkWorderType };
export type { IEvent, IOtherSiteEvent, IBookableResourceBooking, IEventBase };
