import { createContext, ErrorInfo, useCallback, useContext, useEffect, useState } from "react";
import { IAvailability, IBookableresourcecategoryResponse, ICharacteristic, IExpandCalendarAvailabity, IExpandCalendarResponse, IResource, IResourceIngress, statecode } from "../interfaces/resource";
import { IProviderProps } from "../interfaces/provider";
import { apiContext } from "./api";
import { authContext } from "./auth";
import { EEventStatus } from "../helpers/calendar";
import { renderGenericFallbackUi } from "../components/error-boundary/generic-fallback-ui";
import { Icon } from "@fluentui/react";
import { addMonths, format, subMonths } from "date-fns";
import { IEvent } from "../interfaces/event";
import { leaveFormatter } from "../helpers/shifts/shiftsFormatter";

interface IResourceContext {
    loading: string
    getResource: (id: string) => IResource | null
    getAllResources: () => IResource[]
    getResourceCount: () => number
    setResources: (resources: IResource[]) => void
    updateAvailability: (availability: IAvailability[]) => void
    getAvailabilities: () => IAvailability[]
    getAvailabilityByResource: (resourceId: string) => EEventStatus;
    getLeaves(): IEvent[];
}

const resourcesContext = createContext<IResourceContext>({
    loading: '',
    getAllResources: () => ([]),
    getResource: () => null,
    getResourceCount: () => 0,
    setResources: (resources: IResource[]) => { },
    updateAvailability: (availability: IAvailability[]) => { },
    getAvailabilities: () => [],
    getAvailabilityByResource: (resourceId: string) => (EEventStatus.error),
    getLeaves: () => ([])
})

const ResourceProvider = (props: IProviderProps) => {
    const [resources, setResources] = useState<IResource[]>()
    const [availability, setAvailability] = useState<IAvailability[]>([])
    const [loading, setLoading] = useState<string>('Resources')
    const { get, post } = useContext(apiContext)
    const { isAuthed } = useContext(authContext)
    const [loadError, setLoadError] = useState(false)
    const [leaveStore, setLeaveStore] = useState<IEvent[]>([]);

    const getBetweenFilter = (sd: Date, ed: Date, startColName: string, endColName: string): string => {
        //add/remove a day to include boundaries events
        var sdStr = format(sd, 'yyyy-MM-dd')
        var edStr = format(ed, 'yyyy-MM-dd')
        //https://forums.asp.net/t/1912609.aspx?Validate+overlapping+of+two+date+range
        let filter = `Microsoft.Dynamics.CRM.Between(PropertyName='${startColName}',PropertyValues=["${sdStr}","${edStr}"])`
        filter += ` or Microsoft.Dynamics.CRM.Between(PropertyName='${endColName}',PropertyValues=["${sdStr}","${edStr}"])`;
        filter += ` or (${startColName} le ${sdStr} and ${endColName} ge ${edStr})`;
        return `(${filter})`;
    }

    useEffect(() => {
        const loadResources = async (start: Date, end: Date) => {
            try {
                const newResources: IResource[] = []
                const siteId = localStorage.getItem('siteid');

                //load leave requests for past and future six months
                let today = new Date();
                let leaveFilter = getBetweenFilter(subMonths(today, 6), addMonths(today, 6), 'msdyn_starttime', 'msdyn_endtime')

                if (!siteId) {
                    // handel error
                }
                const resourceCategories: { value: IBookableresourcecategoryResponse[] } = await get('/Entity/bookableresourcecategories', {
                    select: 'name,description,illumina_displaycolour',
                    filter: 'illumina_displaycolour ne null'
                });

                const req: { value: IResourceIngress[] } = await get('/Entity/bookableresources',
                    {
                        select: 'name,illumina_resourcesummary, resourcetype,illumina_minimumworkhourperpayroll,illumina_maximumhourspershift,_calendarid_value',
                        orderby: 'lastname desc',                        
                        filter: `statecode eq 0 and resourcetype eq 3 and illumina_BookableResource_illumina_sitelocation_il/any(o:o/illumina_sitelocationid eq '${siteId}')`,
                        expand: 'UserId($select=firstname,lastname,photourl),' +
                            'bookableresource_bookableresourcecharacteristic_Resource($select=name,bookableresourcecharacteristicid,_ratingvalue_value,illumina_expirydate,_characteristic_value,statecode;$filter=statecode eq 0),' +
                            
                            'illumina_BookableResource_illumina_sitelocation_il($select=illumina_name;$filter=illumina_sitelocationid eq ' + siteId + '),' +
                            'bookableresource_bookableresourcecategoryassn_Resource($select=_resourcecategory_value),' +
                            `msdyn_bookableresource_msdyn_timeoffrequest_Resource($filter=statecode eq 1 and _msdyn_approvedby_value ne null and (${leaveFilter}))`
                    })

                req.value.forEach((res) => {
                    if (!res.illumina_BookableResource_illumina_sitelocation_il.find(location => location.illumina_sitelocationid === siteId)) return;

                    let resource = {
                        personalNote: res.illumina_resourcesummary,
                        title: res.name,
                        id: res.bookableresourceid,
                        profileImageUrl: res.UserId?.photoUrl,
                        minimumWorkHoursPerPayroll: res.illumina_minimumworkhourperpayroll as number,
                        maximumWorkHourPerPayroll: res.illumina_maximumworkhourperpayroll as number,
                        maxHoursPerShift: res.illumina_maximumhourspershift,
                        calendarId: res._calendarid_value,
                        characteristics: res.bookableresource_bookableresourcecharacteristic_Resource
                            .map((ch): ICharacteristic => ({
                                name: ch.name,
                                id: ch.bookableresourcecharacteristicid,
                                ratingName: ch['_ratingvalue_value@OData.Community.Display.V1.FormattedValue'],
                                expiryDate: ch.illumina_expirydate,
                                characteristicId: ch['_characteristic_value']                  
                            })).sort((x,y)=>x.name.localeCompare(y.name)),
                        siteLocation: res.illumina_BookableResource_illumina_sitelocation_il,
                        index: newResources.length,
                        categories: [],
                        approvedLeaves: []
                    } as IResource;
                    newResources.push(resource);
                    res.bookableresource_bookableresourcecategoryassn_Resource?.forEach(x => {
                        let rc = resourceCategories.value.find(y => y.bookableresourcecategoryid == x._resourcecategory_value);
                        if (rc != null)
                            resource.categories.push({
                                description: rc.description,
                                displayColour: rc.illumina_displaycolour,
                                id: rc.bookableresourcecategoryid,
                                name: rc.name
                            });
                    });
                    res.msdyn_bookableresource_msdyn_timeoffrequest_Resource.forEach(x => {
                        resource.approvedLeaves.push({ start: new Date(x.msdyn_starttime), end: new Date(x.msdyn_endtime), id: x.msdyn_timeoffrequestid });
                    })
                });

                let leaves: IEvent[] = [];
                newResources.forEach(r => {
                    r.approvedLeaves.forEach(al => {
                        let e = leaveFormatter(al, r);
                        leaves.push(e);
                    })
                });
                setLeaveStore(leaves);

                newResources?.sort((a, b) => {
                    if (a.title > b.title) return 1;
                    if (a.title < b.title) return -1;
                    return 0
                }).forEach((resource, index) => {
                    resource.index = index;
                });



                setLoading('Calendars')

                const expandCalendarRequest = {
                    calendarIds: newResources.map(x => x.calendarId),
                    start: start,
                    end: end
                }
                const expandCalendarResponses: IExpandCalendarResponse[] = await post('/Rostering/expandcalendars', expandCalendarRequest);
                expandCalendarResponses.forEach(r => {
                    var br = newResources.find(nr => nr.calendarId == r.calendarId);
                    if (br) {
                        br.availabilities = r.availabilities.map((x: IExpandCalendarAvailabity) => {
                            return { ...x, start: new Date(x.start), end: new Date(x.end) };
                        });
                    }
                })

                newResources.push({
                    title: "Unassigned",
                    id: "0",
                    index: newResources.length,
                    characteristics: [],
                    categories: [],
                    approvedLeaves: []
                })
                setResources(newResources)
                setLoading('')
            } catch (e) {
                setLoadError(true)
            }

        }
        if ((!resources || resources.length < 1) && isAuthed) {
            let tempStart = subMonths(new Date(), 6);
            let tempEnd = addMonths(new Date(), 6);
            loadResources(tempStart, tempEnd)
        }
    }, [resources, isAuthed])

    const getResource = (id: string) => {
        if (!resources) {
            return null
        }
        return resources.find(res => res.id === id) ?? null
    }
    const getResources = useCallback(() => {
        return resources ?? []
    }, [resources])

    const getLeaves = useCallback(() => {
        return leaveStore ?? []
    }, [resources])


    const getResourceCount = (): number => {
        return resources ? resources.length : 0
    }

    const overrideResources = (nResources: IResource[]) => {
        setResources(nResources)
    }

    const updateAvailability = (availability: IAvailability[]) => {
        setAvailability(availability)
    }

    const getAvailabilities = useCallback(() => {
        return availability ?? []
    }, [availability])

    const getAvailabilityByResource = (resourceId: string): EEventStatus => {
        const foundAvailability = availability.find(av => av.resourceId = resourceId)
        if (foundAvailability) {
            return foundAvailability.availability
        }
        return EEventStatus.valid

    }

    return (
        <resourcesContext.Provider value={{
            loading,
            getResource,
            getResourceCount,
            getAllResources: getResources,
            setResources: overrideResources,
            getAvailabilities,
            updateAvailability,
            getAvailabilityByResource,
            getLeaves
        }}>
            {loadError ? (
                <div style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    color: 'rgb(243, 112, 55)',
                    fontSize: '12px'
                }}>
                    <Icon iconName="error" style={{ fontSize: '24px' }} />
                    <p style={{ marginBottom: '0' }}>An error occurred whilst loading {loading.toLowerCase()}.</p>
                    <p>
                        Please <a
                            onClick={(e) => { e.preventDefault(); window.location.reload() }}
                            href={window.location.href}
                            style={{ color: 'rgb(243, 112, 55)', fontWeight: '500' }}
                        >
                            reload
                        </a> the page.
                    </p>
                </div>
            ) : (
                props.children
            )}
        </resourcesContext.Provider>
    )
}

export { ResourceProvider, resourcesContext }