import { EventApi } from "@fullcalendar/core";
import { format } from "date-fns";
import React, { useContext } from "react";
import './assets/event.scss'
import { ChangeStatus, ECalendarStatus, IEvent } from "../../../interfaces/event";
import { FontIcon, IconButton, Spinner, SpinnerSize, TooltipHost } from "@fluentui/react";
import { truncate } from "../../../helpers/truncate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { shiftsContext } from "../../../providers/shifts";
import { buildCrmUrl } from "../../../helpers/crmUrlBuilder";
import { IResourceData, JobType } from "../../../interfaces/resource";

interface IPropsWarningIcons {
    id?: string
}

const OvertimeIcon = ({ id }: IPropsWarningIcons) => {
    return (
        <TooltipHost content={'Overtime'} key={`overtimeIcon_${id}`}>
            <FontIcon
                iconName="Clock"
                style={{ fontSize: '1em', color: 'var(--color-warningIcon)' }}
            />
        </TooltipHost>
    )

}

const ResourceNotAvailableIcon = ({ id }: IPropsWarningIcons) => {
    return (
        <TooltipHost content={'Resource not available'} key={`resourceNotAvailableIcon_${id}`}>
            <FontIcon
                iconName="IncidentTriangle"
                style={{ fontSize: '1em', color: 'var(--color-errorIcon)' }}
            />
        </TooltipHost>
    )

}

const MissingSkillsIcon = ({ id }: IPropsWarningIcons) => {
    return (
        <TooltipHost content={'Missing/Expired Skills'} key={`missingSkillsIcon_${id}`}>
            <FontIcon
                iconName="Library"
                style={{ fontSize: '1em', color: 'var(--color-warningIcon)' }}
            />
        </TooltipHost>
    )

}

const JobBiddingIcon = ({ id }: IPropsWarningIcons) => {
    return (
        <div key={`jobBiddingIcon_${id}`}>
            <TooltipHost content={'Job Bidding'} key={`jobBiddingIcon_${id}`}>
                <FontAwesomeIcon icon={faBell} color={'#f37037'} />
            </TooltipHost>
        </div>
    )

}

const EventRender = (
    event: EventApi,
    getShift: (eventId: string) => IEvent | undefined,
    isDragging: boolean,
    checkCharacteristics: (eventId: string, resourceId: string) => boolean,
    getResourceData: (id: string) => IResourceData,
    checkIsModified: (originalResourceID: string, resourceId: string) => ChangeStatus,
    eventClick: (event: EventApi) => void,
    isCancellationReviewPending: (shift: IEvent | undefined) => boolean
) => {
    if ((event.display) === 'unavailable') {
        return (<div role="button" className="eventContainer unavailable border-solid">
            <div className="warning-icons-container">
            </div>
            <div className="eventDescription">
                <p>
                    <strong>
                        {format(event.start as Date,'dd/MM/yyyy hh:mm')} - {format(event.end as Date,'dd/MM/yyyy hh:mm')}
                    </strong>                    
                </p>
                <p><strong>On Leave</strong></p>
            </div>
        </div>)
    }

    const shift = getShift(event.id);

    if (event.extendedProps.loading || shift?.validating) {
        return (
            <div className={`eventContainer loading`}>
                <Spinner size={SpinnerSize.medium} />
            </div>
        )
    }


    // Resource Details
    const eventResources = event.getResources()[0];

    // Resource Data
    const resourceData = getResourceData(eventResources.id);

    // Calendar status of the event
    const calendarStatus = event.extendedProps.calendarStatus

    const getStatus = (): string => {
        if (shift?.resourceId !== eventResources.id) {
            // Event will be unassigned if dropped
            if (isResourceUnassigned()) {
                return 'info';
            }

            // Event will be overtime if dropped
            if (isOvertime()) {
                return 'warning';
            }

            // Event will be missing characteristics if dropped
            if (isResourceMissingCharacteristics()) {
                return 'warning';
            }

            // Event will have no issues if dropped
            else {
                return 'success';
            }
        } else {
            // Error Status
            if (calendarStatus === ECalendarStatus.error) return 'error'

            // Unassigned
            if (shift?.resourceId === "0") return 'info'

            // Overtime
            if (resourceData.allSitesHoursWorked > resourceData.maxHoursAllowedPerPayroll) return 'warning';

            // Issue Status
            if (calendarStatus === ECalendarStatus.issue) return 'warning';

            // Event will be missing characteristics if dropped
            if (isResourceMissingCharacteristics()) {
                return 'warning';
            }

            // Assigned, no issues
            return 'success'
        }
    }

    const getBorderStyle = () => {
        let cssClass = isModified() ? 'border-dotted' : 'border-solid';
        if (isCancellationReviewPending(shift))
            cssClass = cssClass + ' border-solid-cancel-request';
        return cssClass;
    }

    const getWarningIcons = (): React.ReactNode => {
        if (event.getResources().length === 0) {
            return;
        }


        const icons: JSX.Element[] = [];

        // Unassigned Check
        if (isNotDefined()) {
            return [];
        }

        // Missing Skills Check
        if (!checkCharacteristics(event.id, eventResources.id)) {
            icons.push(<MissingSkillsIcon id={event.id} key={`missing-skills-icon_${event.id}`} />);
        }

        // Not Available Check
        if (event.extendedProps.unavailable) {
            icons.push(<ResourceNotAvailableIcon id={event.id} key={`error-icon_${event.id}`} />);
        }

        // Overtime Check
        if (isOvertime()) {
            icons.push(<OvertimeIcon id={event.id} key={`overtime-icon_${event.id}`} />);
        }

        const iconsElement = <div className={'eventIcons'}> {icons} </div>;

        return icons.length > 0 ? iconsElement : <></>
    }

    const isResourceUnassigned = () => {
        if (isDragging) {
            return eventResources.id === "0" || !eventResources.id;
        } else {
            return shift?.resourceId === "0"
        }
    }

    const isOvertime = () => {
        let shiftDurationInHour = ((shift?.shiftLength ?? 0) / 60);
        if (isDragging) {
            return (resourceData.allSitesHoursWorked + shiftDurationInHour) > resourceData.maxHoursAllowedPerPayroll ||
                shiftDurationInHour > resourceData.maxHoursPerShift;
        } else {
            return shift?.isOverTime || resourceData.allSitesHoursWorked > resourceData.maxHoursAllowedPerPayroll ||
                (resourceData?.maxHoursPerShift && shiftDurationInHour > resourceData?.maxHoursPerShift);
        }
    }

    const isResourceMissingCharacteristics = () => !checkCharacteristics(event.id, eventResources.id);

    const isModified = () => {
        if (isDragging) {
            return checkIsModified(shift?.originalResourceID ?? '', eventResources.id) === ChangeStatus.Modified;
        } else {
            return event.extendedProps.changeStatus === ChangeStatus.Modified
        }
    }

    const isNotDefined = () => {
        const resource = event.getResources()[0]
        return resource.id === '0';
    }

    const getTimeAsString = (): string => {
        const time = event.extendedProps.shiftLength
        if (time < 60) {
            return `${time} min`
        }
        return `${time / 60} hrs`
    }

    return (
        <div role={'button'} className={`eventContainer ${getStatus()} ${getBorderStyle()}`} onClick={() => eventClick(event)}>
            {event.extendedProps.displayColor && <div className="event-type-color" style={{ backgroundColor: event.extendedProps.displayColor }}>

            </div>}
            {event.extendedProps.isJobBidding && isNotDefined() &&
                <div className={'bellContainer'}>
                    <div className={'bell'}>
                        {event.extendedProps.bidders ? event.extendedProps.bidders.length : 0} <JobBiddingIcon
                            id={event.id} />
                    </div>
                </div>
            }
            <div className={'warning-icons-container'}>
                {getWarningIcons()}
            </div>

            <div className={'eventDescription'}>
                <p>
                    <strong>
                        {format(event.start ?? new Date(), 'h:mma')}
                        &nbsp;-&nbsp;
                        {format(event.end ?? new Date(), 'h:mma')}
                    </strong>
                    <span>
                        &nbsp;{"("}{getTimeAsString()}{")"}
                    </span>
                </p>
                <p>
                    {event.extendedProps.description && <strong> {truncate(event.extendedProps.description)} </strong>}
                </p>
            </div>
            <div style={{ position: 'absolute', right: '5px', bottom: '0', fontSize: '0.60rem' }}>
                {event.extendedProps.displayCost ? '$' + event.extendedProps.displayCost.toLocaleString(undefined, { maximumFractionDigits: 2 }) : ''}
            </div>


            {/*<div className={"eventIndicator"}/>*/}
        </div>
    )
}

const OtherSiteEventRender = ({ event }: { event: EventApi }) => {
    const { getEnvironmentInfo } = useContext(shiftsContext)
    const getTimeAsString = (): string => {
        const time = event.extendedProps.shiftLength
        if (time < 60) {
            return `${time} min`
        }
        return `${time / 60} hrs`
    }

    const renderInfo = () => {
        return event.extendedProps.jobType === JobType.ResidentialDisabilityandAgedCareService
          ? event.extendedProps.siteLocation
          : event.extendedProps.customerFullName;
      };

      return (
        <TooltipHost content={'Resource is scheduled at another site.'}>
          <div
            style={{
              background: '#efefef',
              height: '50px', 
              margin: '2px 0',
              display: 'flex',
              flexDirection: 'column', 
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              border: '1px dotted #808080',
              userSelect: 'none',
              position: 'relative',
            }}
          >
            <IconButton
              className={'external-link-button'}
              iconProps={{ iconName: 'OpenInNewTab' }}
              href={buildCrmUrl(getEnvironmentInfo(), 'msdyn_workorder', event.id)}
              target={'_blank'}
            />
            <div>
              <strong>
                {format(event.start ?? new Date(), 'h:mma')}
                &nbsp;-&nbsp;
                {format(event.end ?? new Date(), 'h:mma')}
              </strong>
              <span>
                &nbsp;({" "}
                {getTimeAsString()}
                {")"}
              </span>
            </div>
            <div>{renderInfo()}</div>
            <div>{event.title}</div>
          </div>
        </TooltipHost>
      );
    };

export { EventRender, OtherSiteEventRender }
