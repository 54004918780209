import { EventSourceInput } from "@fullcalendar/core";
import {
    ChangeStatus,
    EBookingStatus,
    ECalendarStatus,
    ESystemStatus,
    IBookableResourceBooking,
    IEvent,
    IOtherSiteEvent
} from "../../interfaces/event";
import { IIncidentType } from "../../interfaces/incidentTypes";
import { IOtherSiteWorkOrder, IResource, ITimeOffRequest, IWorkOrder, IWorkOrderType, ShiftCancellationRequestStatus } from "../../interfaces/resource";
import addMinutes from "date-fns/addMinutes";
import { differenceInMinutes } from "date-fns";

const shiftsFormatter = (apiShift: IWorkOrder, shiftTypes: IWorkOrderType[], incidentTypes: IIncidentType[]): IEvent | undefined => {
    if (apiShift.msdyn_systemstatus === ESystemStatus.Canceled) {
        return;
    }

    const bidders = apiShift.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder.filter(x => x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() == 'expressed interest'); //Expressed interest is the display name - id can't be used as it might change in prod
    const assignedWorkBookings = apiShift.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder.filter(x => x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'expressed interest'
        && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'canceled' && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'coordinator canceled');
    const event: Partial<IEvent> = {}

    if (assignedWorkBookings.length > 0) {
        const assignedBooking = assignedWorkBookings[0];
        event.resourceId = assignedBooking['_resource_value']
        event.originalResourceID = assignedBooking['_resource_value']
        event.bookableResourceStatus = assignedBooking['_bookingstatus_value'] as any;
        event.BookingId = assignedBooking.bookableresourcebookingid;
        event.isCancellationRequestPendingOriginal = assignedBooking.illumina_cancellationrequeststatus == ShiftCancellationRequestStatus.Pending;
        event.cancellationRequestComments = assignedBooking.illumina_cancellationrequestcomments;

    } else {
        event.resourceId = '0'
        event.originalResourceID = '0'
        event.bookableResourceStatus = EBookingStatus.Scheduled
        event.BookingId = '';
    }


    const primaryIncidentType = () => {
        if (apiShift.msdyn_primaryincidenttype && apiShift.msdyn_primaryincidenttype.msdyn_incidenttypeid) {
            return incidentTypes.find(type => {
                return type.id === apiShift.msdyn_primaryincidenttype.msdyn_incidenttypeid
            })
        }
        return {
            name: 'Not Required',
            id: '0',
            characteristic: []
        }
    }
    const sd = new Date(apiShift.msdyn_timewindowstart);
    const ed = apiShift.msdyn_totalestimatedduration ? addMinutes(sd, apiShift.msdyn_totalestimatedduration) : new Date(apiShift.msdyn_timewindowend); // if duration exists, use duration. Otherwise use enddate
    const shiftDuration = apiShift.msdyn_totalestimatedduration ? apiShift.msdyn_totalestimatedduration : differenceInMinutes(ed, sd)
    return {
        title: apiShift.msdyn_name,
        start: sd,
        end: ed,
        id: apiShift.msdyn_workorderid,
        description: apiShift.msdyn_workordersummary ? apiShift.msdyn_workordersummary : apiShift.msdyn_name,
        status: apiShift.msdyn_systemstatus,
        preferredResourceId: apiShift._msdyn_preferredresource_value,
        preferredResourceName: apiShift['_msdyn_preferredresource_value@OData.Community.Display.V1.FormattedValue'],
        jobType: apiShift['illumina_jobtype@OData.Community.Display.V1.FormattedValue'],
        displayColor: shiftTypes.find(x => x.msdyn_workordertypeid == apiShift._msdyn_workordertype_value)?.illumina_displaycolour,
        incidentType: primaryIncidentType(),
        bidders: bidders.map(x => <IBookableResourceBooking>{
            bookingStatus: x._bookingstatus_value,
            id: x.bookableresourcebookingid,
            bookableResourceId: x._resource_value
        }),
        calendarStatus: (event.resourceId === '0') ? ECalendarStatus.scheduled : ECalendarStatus.assigned,
        serviceAccount: {
            name: apiShift.msdyn_serviceaccount.name,
            accountId: apiShift.msdyn_serviceaccount.accountid,
            accountNumber: apiShift.msdyn_serviceaccount.accountnumber
        },
        workOrderType: {
            msdyn_workordertypeid: apiShift.msdyn_workordertype?.msdyn_workordertypeid,
            msdyn_name: apiShift.msdyn_workordertype?.msdyn_name,
            illumina_displaycolour: apiShift.msdyn_workordertype?.illumina_displaycolour
        },
        isJobBidding: apiShift.illumina_openforshiftjobbidding,
        changeStatus: ChangeStatus.UnChanged,
        shiftLength: shiftDuration,
        loading: false,
        ...event
    } as IEvent
}

const leaveFormatter = (leave: ITimeOffRequest, resource: IResource): IEvent => {
    return {
        title: "On Leave",
        start: leave.start,
        end: leave.end,
        id: leave.id,
        description: '',
        resourceId: resource.id,
        originalResourceID: resource.id,
        changeStatus: ChangeStatus.UnChanged,
        loading: false,
        editable: false,
        display: 'unavailable',
        resourceEditable: false
    } as IEvent
}

const otherSiteShiftFormatter = (apiShift: IOtherSiteWorkOrder, shiftTypes: IWorkOrderType[]): IOtherSiteEvent | undefined => {
    const assignedWorkBookings = apiShift.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder.filter(x => x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'expressed interest'
        && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"] != 'Canceled' && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'coordinator canceled');

    const sd = new Date(apiShift.msdyn_timefrompromised);
    const ed = apiShift.msdyn_totalestimatedduration
        ? addMinutes(sd, apiShift.msdyn_totalestimatedduration)
        : new Date(apiShift.msdyn_timewindowend);
        const shiftDuration = apiShift.msdyn_totalestimatedduration ? apiShift.msdyn_totalestimatedduration : differenceInMinutes(ed, sd)

    if (assignedWorkBookings.length > 0) {
        const event: IOtherSiteEvent = {
            id: apiShift.msdyn_workorderid,
            editable: false,
            resourceEditable: false,
            startEditable: false,
            otherSite: true,
            resourceId: assignedWorkBookings[0]._resource_value,
            title: apiShift.msdyn_name,
            start: sd,
            end: ed,
            shiftLength: shiftDuration,
            displayColor: shiftTypes.find(x => x.msdyn_workordertypeid == apiShift._msdyn_workordertype_value)?.illumina_displaycolour,
            jobType: apiShift.illumina_jobtype,
            customerFullName: apiShift.illumina_ServiceContact?.fullname || "Customer Missing",
            siteLocation: apiShift["_illumina_sitelocationfromid_value@OData.Community.Display.V1.FormattedValue"] || "Site Location missing",
            workOrderType: {
                msdyn_workordertypeid: apiShift.msdyn_workordertype?.msdyn_workordertypeid,
                msdyn_name: apiShift.msdyn_workordertype?.msdyn_name,
                illumina_displaycolour: apiShift.msdyn_workordertype?.illumina_displaycolour
            }

        }

        return event;
    }


}

export { shiftsFormatter, otherSiteShiftFormatter, leaveFormatter }